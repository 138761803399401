import React from "react";
import {
  createTheme,
  withStyles,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { green } from "@material-ui/core/colors";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";
import { ExternalLink } from 'react-external-link';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, addDoc, getFirestore, getDocs, setDoc, Timestamp } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics";
import Airtable from "airtable";
import FeaturedPost from './FeaturedPost';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

//Your web app's Firebase configuration
//For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCYaXL5e4wTZ_H4snWsnwh8Nn4gg6cDnDo",
  authDomain: "stockanalyser-323905.firebaseapp.com",
  projectId: "stockanalyser-323905",
  storageBucket: "stockanalyser-323905.appspot.com",
  messagingSenderId: "332084981505",
  appId: "1:332084981505:web:1c1657893bf82875757500",
  measurementId: "G-N2307ZT90Y"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// const currencies = [
//   {
//     value: "USD",
//     label: "$"
//   },
//   {
//     value: "EUR",
//     label: "€"
//   },
//   {
//     value: "BTC",
//     label: "฿"
//   },
//   {
//     value: "JPY",
//     label: "¥"
//   }
// ];

const frequencies = [
  {
    value: "Once-off",
    label: "Once-off"
  },
  {
    value: "Daily",
    label: "Daily"
  },
  {
    value: "Weekly",
    label: "Weekly"
  },
  {
    value: "Fortnightly",
    label: "Fortnightly"
  },
  {
    value: "Monthly",
    label: "Monthly"
  },
  {
    value: "Quarterly",
    label: "Quarterly"
  },
  {
    value: "Bi-annually",
    label: "Bi-annually"
  },
  {
    value: "Yearly",
    label: "Yearly"
  }
];

var startPrice = 0;
let isCalculateBtnDisabled = false;

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    },
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "50ch"
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  articleContent: {
    padding: theme.spacing(6, 0, 6),
  },
  heading: {
    padding: theme.spacing(6, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
  },
}));

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

const featuredPosts = [
  {
    title: 'Behind the scenes of the passive investment calculator',
    date: 'Oct 28, 2021',
    description:
      'An in-depth explanation of how the tool calculates the outputs',
    image: "C:/Users/krish/PortfolioAnalyser/webapp/src/recha-oktaviani-h2aDKwigQeA-unsplash.jpg",
    imageLabel: 'Image Text',
  },
  // {
  //   title: 'An easy win in investing',
  //   date: 'Sep 15, 2021',
  //   description:
  //     'Insights into the value of passive vs. active investing strategies',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  // },
];

const SpinnerAdornment = withStyles(useStyles)(props => (
  <CircularProgress
    className={props.classes.spinner}
    size={20}
  />
))

function ButtonComponent(props) {
  const { onClick, loader } = props;
  return (
    <Button variant="contained" onClick={onClick} disabled={loader}>
      {loader && <CircularProgress size={14} />}
      {!loader && 'Click Me'}
    </Button>
  );
}

export default function MultilineTextFields() {
  const classes = useStyles();
  const theme = createTheme({
    palette: {
      primary: green
    }
  });

  const [invalidEntry, setInvalidEntry] = React.useState(false);
  //still need to figure out error catching on button click: https://stackoverflow.com/questions/61847276/react-materialui-set-error-on-textfield-component-after-button-is-clicked

  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              Passive Investment Calculator
            </Typography>

            <nav>
              <Link variant="button" color="textPrimary" to="/" className={classes.link}>
                Calculator
              </Link>
              <Link variant="button" color="textPrimary" to="/articles" className={classes.link}>
                Articles
              </Link>
            </nav>
            {/* <Button href="#" color="primary" variant="outlined" className={classes.link}>
              Sign Up
            </Button> */}
          </Toolbar>

        </AppBar>

        <Switch>
          <Route path="/articles">
            <Articles />
          </Route>
          <Route path="/">
            <Calculator />
          </Route>
        </Switch>
      </Router>
      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        {/* <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <Router>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
              </Router>
            </Grid>
          ))}
        </Grid> */}
        <Box mt={1}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <ExternalLink href="https://passiveinvestmentcalculator.com/">
        <span>Passive Investment Calculator</span>
      </ExternalLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Calculator() {
  const classes = useStyles();
const theme = createTheme({
  palette: {
    primary: green
  }
});

  const { GoogleSpreadsheet } = require('google-spreadsheet');

  //Airtable Setup
  const base = new Airtable({ apiKey: 'keyvsv3xbEOmpBfhd' }).base('appLPp4qDixM9uCKo');

  const creds = require('C:/Users/krish/PortfolioAnalyser/webapp/src/credentials.json'); // the file saved above
  const doc = new GoogleSpreadsheet('1bV5sQrVW2j7_EW0WucHJju9rp2AxaZGT5cZNzofxXFc');

  doc.useServiceAccountAuth(creds);

  const delay = ms => new Promise(res => setTimeout(res, ms));
  var [loader, setLoader] = React.useState(false);

  const [ticker, setTicker] = React.useState("MSFT");
  const saveTicker = (event) => {
    setTicker(event.target.value);
  };

  const [initialInv, setInitialInv] = React.useState("10000");
  const saveInitialInv = (event) => {
    setInitialInv(event.target.value);
  };

  const [frequency, setFrequency] = React.useState("Monthly");
  const handleFrequency = (event) => {
    setFrequency(event.target.value);
  };

  const [recurringInv, setRecurringInv] = React.useState("1000");
  const saveRecurringInv = (event) => {
    setRecurringInv(event.target.value);
  };

  const [years, setYears] = React.useState("10");
  const saveYears = (event) => {
    setYears(event.target.value);
  };

  const [date1, setDate1] = React.useState("2021-08-10");
  const saveDate1 = (event) => {
    setDate1(event.target.value);
  };

  const [date2, setDate2] = React.useState("2020-08-10");
  const saveDate2 = (event) => {
    setDate2(event.target.value);
    //handleCAGR();
  };

  var [pastCAGR, setPastCAGR] = React.useState(0);
  var [sharePrice, setSharePrice] = React.useState(0);
  var [lastDividend, setLastDividend] = React.useState(0);
  var [avgDividend, setAvgDividend] = React.useState(0);
  var [initialStocks, setInitialStocks] = React.useState(0);
  var [recurringStocks, setRecurringStocks] = React.useState(0);
  var [dividendStocks, setDividendStocks] = React.useState(0);
  var [totalStocks, setTotalStocks] = React.useState(0);
  var [cagrResult, setCagrResult] = React.useState(0);
  var [expectedValue, setExpectedValue] = React.useState(0);
  var [investedValue, setInvestedValue] = React.useState(0);
  var [buttonPressed, setButtonPressed] = React.useState(false);

  const sendToGoogleSheets = async () => {
    setLoader(true);
    await doc.loadInfo();
    var sheet = doc.sheetsByIndex[1];
    await sheet.loadCells('B1:B44');

    var test;
    var validTicker = false;

    console.log("Sending data...")
    var startDateVal = new Date();
    var pastDateVal = new Date();
    var todayDate = new Date();

    startDateVal.setDate(todayDate.getDate() - 1); //yesterday's date
    var startDateStr = startDateVal.toISOString().substr(0, 10);
    //TO DO check if date is on a weekend by using alpha, calling the API, and checking if error is received

    pastDateVal.setDate(todayDate.getDate() - (10 * 365)); //10 years from today's date
    var pastDateStr = pastDateVal.toISOString().substr(0, 10);

    //do error checks
    try {
      await fetch(
        "https://www.alphavantage.co/query?function=TIME_SERIES_DAILY_ADJUSTED&symbol=" +
        ticker +
        "&outputsize=full&apikey=2ZYQXBTMTSIG4O83"
      ).then((response) => response.json())
        .then((jsonData) => {
          //check if ticker valid
          test = jsonData["Meta Data"]["1. Information"];
          //find a valid start date
          try {
            test = jsonData["Time Series (Daily)"][startDateStr]["4. close"];
          } catch {
            startDateVal.setDate(todayDate.getDate() - 2);
            startDateStr = startDateVal.toISOString().substr(0, 10);
            try {
              test = jsonData["Time Series (Daily)"][startDateStr]["4. close"];
            } catch {
              startDateVal.setDate(todayDate.getDate() - 3);
              startDateStr = startDateVal.toISOString().substr(0, 10);
            }
          }
          //find a valid past date
          try {
            test = jsonData["Time Series (Daily)"][pastDateStr]["4. close"];
          } catch {
            pastDateVal.setDate(todayDate.getDate() - 1);
            pastDateStr = pastDateVal.toISOString().substr(0, 10);
            try {
              test = jsonData["Time Series (Daily)"][pastDateStr]["4. close"];
            } catch {
              pastDateVal.setDate(todayDate.getDate() - 2);
              pastDateStr = pastDateVal.toISOString().substr(0, 10);
              try {
                test = jsonData["Time Series (Daily)"][pastDateStr]["4. close"];
              } catch {
                alert("10 year return unavailable for this ticker");
                setLoader(false);
                validTicker = true;
                test = jsonData["Time Series (Daily)"][pastDateStr]["4. close"];
              }
            }
          }
        })
    } catch {
      // handle your errors here
      if (validTicker == false) {
        alert("Ticker symbol is not supported or invalid");
      }
      setLoader(false);
      return;
    }

    const tickerCell = sheet.getCellByA1('B1');
    tickerCell.value = ticker;

    const startDateCell = sheet.getCellByA1('B6');
    startDateCell.value = startDateStr;

    const initialInvCell = sheet.getCellByA1('B4');
    initialInvCell.value = initialInv;

    const frequencyCell = sheet.getCellByA1('B12');
    frequencyCell.value = frequency;

    const recurringInvCell = sheet.getCellByA1('B13');
    recurringInvCell.value = recurringInv;

    const pastDateForCAGRCell = sheet.getCellByA1('B17');
    pastDateForCAGRCell.value = pastDateStr;

    const pastDateForDividendsCell = sheet.getCellByA1('B23');
    pastDateForDividendsCell.value = pastDateStr;

    const yearsCell = sheet.getCellByA1('B31');
    yearsCell.value = years;

    await sheet.saveUpdatedCells();
    console.log("The following data has been sent:");

    console.log({
      Ticker: tickerCell.value,
      Initial_Investment: initialInvCell.value,
      Recurring_Investment: recurringInvCell.value,
      Frequency: frequencyCell.value,
      Years: yearsCell.value
    });

    //await delay(3000);

    //await doc.loadInfo();
    //var sheet = doc.sheetsByIndex[1];
    await sheet.loadCells('B1:B44');

    console.log("Collecting results");
    pastCAGR = Number(sheet.getCellByA1('B20').value * 100).toFixed(2);
    cagrResult = Number(sheet.getCellByA1('B44').value * 100).toFixed(2);
    avgDividend = Number(sheet.getCellByA1('B25').value).toFixed(2);
    initialStocks = Number(sheet.getCellByA1('B9').value);
    recurringStocks = Number(sheet.getCellByA1('B36').value);
    dividendStocks = Number(sheet.getCellByA1('B37').value);
    totalStocks = Number(sheet.getCellByA1('B38').value);
    expectedValue = Number(sheet.getCellByA1('B41').value).toFixed(2);
    investedValue = Number(sheet.getCellByA1('B42').value).toFixed(2);
    sharePrice = Number(sheet.getCellByA1('B8').value);
    lastDividend = Number(sheet.getCellByA1('B26').value);

    var lastDividendStr = "";

    if (lastDividend == 90) {
      lastDividendStr = "Quarterly";
    } else if (lastDividend == 180) {
      lastDividendStr = "Bi-Annual";
    }

    //check to see if Google Sheets messed up
    if (isNaN(sharePrice)) {
      alert("An error occurred when collecting the data. Please try again.");
      setLoader(false);
      return;
    }

    setSharePrice(sharePrice);
    setPastCAGR(pastCAGR);
    setCagrResult(cagrResult);
    if (isNaN(avgDividend)) {
      setAvgDividend(0);
    } else {
      setAvgDividend(avgDividend);
    }
    if (isNaN(initialStocks)) {
      setInitialStocks(0);
    } else {
      setInitialStocks(initialStocks);
    }
    setRecurringStocks(recurringStocks);
    if (isNaN(dividendStocks)) {
      setDividendStocks(0);
    } else {
      setDividendStocks(dividendStocks);
    }
    setTotalStocks(totalStocks);
    setExpectedValue(expectedValue);
    setInvestedValue(investedValue);

    console.log("Results collected");
    setLoader(false);

    var string = todayDate.toLocaleString();

    base('Inputs').create(
      {
        "Timestamp": string,
        "Ticker": ticker,
        "Initial_Investment": Number(initialInv),
        "Recurring_Investment": Number(recurringInv),
        "Frequency": frequency,
        "Years": Number(years),
        "10-Year Historic CAGR (%)": pastCAGR,
        "Average Dividend": avgDividend,
        "Dividend Frequency": lastDividendStr,
        "Expected CAGR (%)": cagrResult
      }, function (err, record) {
        if (err) {
          console.error(err);
        }
      });
  }; // loads document properties and worksheets

  return (
    <div>
        <Container maxWidth='md' className={classes.heading}>
          <Typography variant="h6" align='center'>
            Examine how different dollar cost averaging strategies can improve your final return. Use real market data from US stocks and ETFs!
          </Typography>
        </Container>
        <Container fixed>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs>
              <form className={classes.root} noValidate autoComplete="off">
                <div>
                  <Typography variant="h6" gutterBottom>
                    Step 1: Enter the ticker symbol of the stock you want to
                    invest in
                  </Typography>
                  <TextField
                    error={!/[\W_]+/g.test(ticker) ? false : true} //don't allow non-alphanumeric characters
                    id="outlined-select-currency"
                    type="outlined"
                    label="e.g. MSFT"
                    onChange={saveTicker}
                    helperText="Enter only letters and/or numbers"
                    variant="outlined"
                  />
                </div>
                <div>
                  <Typography variant="h6" gutterBottom>
                    Step 2: Enter your initial investment amount (USD)
                  </Typography>
                  <TextField
                    error={/^\d+$/.test(initialInv) ? false : true} //Must be whole integer greater than 0
                    id="outlined-select-currency"
                    label="e.g. 10000"
                    onChange={saveInitialInv}
                    helperText="Must be whole integer greater than 0"
                    variant="outlined"
                  />
                </div>
                <div>
                  <Typography variant="h6" gutterBottom>
                    Step 3: Enter the recurring frequency of future investments
                  </Typography>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select"
                    value={frequency}
                    onChange={handleFrequency}
                    variant="outlined"
                  //TO DO make disabled if person selects once-off investment from earlier
                  >
                    {frequencies.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div>
                  <Typography variant="h6" gutterBottom>
                    Step 4: Enter the recurring amount of future investments (USD)
                  </Typography>
                  <TextField
                    error={/^\d+$/.test(recurringInv) ? false : true} //Must be whole integer greater than 0
                    id="outlined-select-currency"
                    label="e.g. 1000"
                    onChange={saveRecurringInv}
                    helperText="Must be whole integer greater than 0"
                    variant="outlined"
                  />
                </div>
                <div>
                  <Typography variant="h6" gutterBottom>
                    Step 5: Choose the number of years you want to hold the investment for
                  </Typography>
                  <TextField
                    error={/^\d+$/.test(years) ? false : true} //Must be whole integer greater than 0
                    id="standard-number"
                    label="e.g. 10"
                    type="number"
                    onChange={saveYears}
                    helperText="Must be whole integer greater than 0"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                  />
                </div>
                <div>
                  <Box textAlign='left' m={2}>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.margin}
                        onClick={sendToGoogleSheets}
                        type="submit"
                        disabled={loader}
                      >
                        {loader && <CircularProgress size={14} />}
                        {!loader && 'Calculate Future Returns'}
                      </Button>
                    </ThemeProvider>
                  </Box>
                </div>
              </form>
            </Grid>
            <Grid item xs alignContent='center'>
              <div>
                <Box textAlign='center' bgcolor="palevioletred">
                  <Typography variant="h5" gutterBottom>
                    Core assumptions
                  </Typography>
                </Box>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Historic 10 year return for {ticker}: {pastCAGR}%
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Average dividend per share paid over last 10 years: ${avgDividend}
                </Typography>
              </div>
              <div>
                <Box textAlign='center' bgcolor="palevioletred">
                  <Typography variant="h5" gutterBottom>
                    Share purchases over investment lifetime
                  </Typography>
                </Box>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Recent share price: ${sharePrice}
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Shares bought through initial investment: {initialStocks}
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Shares bought through recurring investment: {recurringStocks}
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Shares bought through dividend re-investments: {dividendStocks}
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Total shares bought: {totalStocks}
                </Typography>
              </div>
              <div>
                <Box textAlign='center' bgcolor="palevioletred">
                  <Typography variant="h5" gutterBottom>
                    Analysis of returns
                  </Typography>
                </Box>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Total amount invested over {years} years: ${investedValue}
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Total investment value after {years} years: ${expectedValue}
                </Typography>
              </div>
              <div>
                <Typography variant="h6" gutterBottom>
                  Total return after {years} years: {cagrResult}%
                </Typography>
              </div>
              <div>
                <Box m={4}>
                  <Typography variant="h6" align='center'>
                    <span>Want to request a new feature or report a bug? Click </span>
                    <ExternalLink href="https://7qnciixo8k8.typeform.com/to/OyFJQe1B">
                      <span>here</span>
                    </ExternalLink>
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
    </div>
  );
}

function Articles() {
  const classes = useStyles();
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.articleContent}>
          <Grid container spacing={4} direction="column" alignContent='center'>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
      </Container>
    </ThemeProvider>
  );
}

function Feedback() {
  return <h2></h2>;
}